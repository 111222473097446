import React from 'react';
import ReactDOM from 'react-dom';
import {colors} from "../styles";

const Modal = ({ isShowing, hide, toggleAccept1, hasAccepted, autoFarmPid, depositCallBack }) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        <div className="modal-outer" id="modal">
            <div className="modal-inner max-w-3xl m-auto flex flex-col space-y-6">
                <h2>IMPORTANT NOTICE!</h2>
                <div className="content">
                    This is a permanent lock vault!<br/>
                    Only RAKE Rewards can be withdrawn, <strong style={{ color: 'red' }}>not your initial token deposit</strong>.<br/>
                    80% of locked value goes to RAKE buyback/burn & 20% goes to bR34P buyback/burn!!
                </div>
                <div>
                    <form action="">
                        <input onClick={(e) => toggleAccept1(e.target.checked, autoFarmPid)} type="checkbox" id="ak0" name="ak0"/>
                        <label htmlFor="ak0">I ACKNOWLEDGE THAT I HAVE READ AND UNDERSTOOD THE ABOVE NOTICE </label>
                    </form>
                </div>
                <div className="actions">
                    <button style={{float: "left"}} className="toggle-button btn btn-error" onClick={hide}>
                        BACK
                    </button>

                    <button style={{float: "right"}} className="toggle-button btn btn-primary"
                            onClick={
                                x => {
                                    let hasAccept = hasAccepted(autoFarmPid)
                                    if (hasAccept){
                                        depositCallBack()
                                    } else {
                                        alert("Please accept terms above before proceeding")
                                    }
                                }}
                            >
                        PROCEED
                    </button>

                </div>
            </div>
        </div>
    </React.Fragment>, document.body
) : null;

export default Modal;

const {AutoFarmAddress, RakeTokenAddress, apiDomain} = require('../config')

let mode = "prod"
// let mode = "dev" // same as prod, just using local server, not production.
// let mode = "test"

let serverURLBase =  "http://localhost:3888/"
let serverURLBase2 =  "http://localhost:3889/"
let serverURLBaseHeco =  "http://localhost:3889/"

const _apiDomain = apiDomain
const isStaging = process.env.REACT_APP_IS_STAGING

if (mode == "prod") { 
  serverURLBase = "https://autofarm.network/api/" 
  serverURLBase2 = _apiDomain + (
    isStaging ? 'bsc-staging/' : 'bsc/'
  )
  serverURLBaseHeco = _apiDomain + (
    isStaging ? 'heco-staging/' : 'heco/'
  )
}

module.exports = {
    mode,
    serverURLBase: serverURLBase,
    serverURLBase2: serverURLBase2,
    serverURLBaseHeco,
    autoFarmContractAddress : mode == "test" ? "0x17f619f4eec6742cEa2d287dbbcf61Ba3360172F" : "0x7f7Bf15B9c68D23339C31652C8e860492991760d",
    //mode == "test" ? "0x0df9c5fb57bc3b90e73563e9adb672bea2fd41fb"
    autoFarmV2ContractAddress: {
      bsc: AutoFarmAddress,
      heco: "0xb09a88956730b6b842d9f1cf6f72dd682c2f36f9",
    },

    AUTOv2Address: mode == "test" ? "todoTestAddress": RakeTokenAddress,

    gasLimit: "580000",
    blockExplorerURLBase: {
      bsc: "https://bscscan.com/",
      heco: "https://scan.huobichain.com/",
    }
}


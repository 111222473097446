// tslint:disable:no-console
import { useMemo, useState, useCallback, useEffect } from 'react'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { identity, sortBy, uniqBy, sum, values, compose, mapObjIndexed } from 'ramda'

import { useQuery, useMutation } from 'react-query'
import axios from 'axios'
import { ethers } from 'ethers';
import {BigNumber} from '@ethersproject/bignumber'      // Also works requires BigNumber.from()
import commaNumber from 'comma-number'
import constants from './constants'
import { token } from './lib/numFormat'
import Loading from './Loading'
import Countdown from 'react-countdown';

import {
  Grid,
  IconButton,
  Tooltip,
  Button,
  Switch,
  Collapse
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { Link } from 'react-router-dom'

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import DepositWithdrawButtons from './DepositWithdrawButtons'
import BotNavBar from './BotNavBar'

import DateCountdown from 'react-date-countdown-timer';

import { number } from 'prop-types';

import cloneDeep from 'lodash.clonedeep';
import ThemeContext from '../context/theme'
import Dashboard from './Dashboard'
import Pool from './Pool'
import PoolHeader from './PoolHeader'
import ToolBar from './Toolbar'
import Chain from './Chain'
import useWalletData from './wallet'

let reloadUserWalletDataInProgress = false
let hashCompletionHandled = {}
let initialMountDone = false 

let degenRowOnwards = 99

let userWantsBalancesSaved = {}     // key = pid
let userPendingAUTOSaved = {}     // key = pid
let userStakedWantTokensSaved = {} // key = pid

const instance = axios.create({
  baseURL: constants.serverURLBase2,
});
const instanceHeco = axios.create({
  baseURL: constants.serverURLBaseHeco,
});

const fetchStats = () => instance.get('get_stats').then(({data}) => data)
const fetchFarmData = () => instance.get('get_farms_data').then(({data}) => data)

const refetchStatsInterval = 30000

const Vaults2 = ({ chain, setChain, web3, web3_np, address, connectionOK, notify, chainId }) => {
  const [degen, setDegen] = useState(false)

  const toggleDegen = useCallback(() => {
    setDegen(d => !d)
    setSelectedFarm(null)
  }, [setDegen])

  useEffect(() => {
    setDegen(false)
  }, [chain, setDegen])

  useEffect(() => {
    if (chainId === 56 && chain === 'heco') {
      setChain('bsc')
    }
    if (chainId === 128 && chain === 'bsc') {
      setChain('heco')
    }
  }, [chainId])

  const bscStats = useQuery('bsc-stats', fetchStats, {
    refetchInterval: refetchStatsInterval
  })

  const stats = bscStats

  const bscFarms = useQuery('bsc-farm', fetchFarmData)
  const farms = bscFarms

  const hasDegen = farms?.data?.degenRowOnwards < farms?.data?.table_data?.length

  const walletData = useWalletData(
    farms,
    address,
    web3,
    web3_np,
    connectionOK,
    chain,
    chainId
  );
  const userData = walletData.userData;

  const tvls = useMemo(() => ({
    bsc: bscStats.data?.platformTVL
  }), [bscStats.data])

  const totalTVL = tvls?.bsc

  const totalPendingAUTO = useMemo(
    () => sum(values(userData?.data?.pendingAUTO)),
    [userData?.data?.pendingAUTO]
  )
  const totalStaked = useMemo(
    () => sum(values(mapObjIndexed(
      (staked, pid) => {
        const price = farms?.data?.pools?.[pid]?.wantPrice || 0
        return staked * price
      },
      userData?.data?.staked
    ))),
    [userData?.data?.staked, farms?.data?.pools]
  )

  const deposit = useCallback(({ pid, amt }) => {
    if (!amt) {
      return
    }
    let contract = walletData.autoContract
    const normalizedAmt = (amt.match(/,/g) || []).length === 1
      ? amt.replace(',', '.')
      : amt

    const autoRewards = userData?.data?.pendingAUTO?.[pid]

    const contractDeposit = (pid) => new Promise((resolve, reject) => {
      contract.p.methods.deposit(pid, ethers.utils.parseUnits(normalizedAmt, 18) ).send({ from: address}, (err, data) => { if (err) { console.log(err) } } )
      .on('error', (error) => {
        reject(error)
      })
      .on('transactionHash', (transactionHash) => { notify("Deposit pending..."); console.log(transactionHash, "pending...") })
      .on('receipt', (receipt) => {
        console.log(receipt, "receipt") // contains the new contract address
        if (!hashCompletionHandled[receipt.blockHash]){
          hashCompletionHandled[receipt.blockHash] = true
          let message = 'Deposit complete!'
          if (autoRewards > 0) {
            message += ` ${token(autoRewards)} Rake Harvested`
          }
          notify(message)
          userData.refetch()
          resolve(receipt)
        } 
      })
      .on('confirmation', function(confirmationNumber, receipt){ 
        // console.log(receipt, "confirmation") // contains the new contract address
      })
    })

    // Approve if allowance less than amt
    const vault = walletData.poolsByPid?.[pid]
    if ( !userData.data?.allowances[pid]
    || BigNumber.from(userData.data?.allowances[pid].toString()).lt(ethers.utils.parseUnits(amt, 18))  ){
      notify("Approval required.")
      let wantTokenContract = vault.contract
      let autoFarmContractAddress = constants.autoFarmV2ContractAddress[chain]
      return new Promise((resolve, reject) => {
        wantTokenContract.p.methods.approve(
          autoFarmContractAddress,
          ethers.utils.parseUnits("5", 76)
        ).send({ from: address }, (err, data) => { if (err) { console.log(err) } } )
        .on('error', (error) => {  console.log(error); reject(error)  })
        .on('transactionHash', (transactionHash) => {
          notify("Approving...")
          console.log(transactionHash, "Approving...") })
        .on('receipt', (receipt) => {
          console.log("receipt") // contains the new contract address
          if (!hashCompletionHandled[receipt.blockHash]){
            hashCompletionHandled[receipt.blockHash] = true
            notify("Approval complete!")
            resolve(contractDeposit(pid))
          } else {
            console.log("hashCompletionHandled")
          }
        })
        .on('confirmation', (confirmationNumber, receipt) => { 
          console.log(receipt, "confirmation") // contains the new contract address
        })
      })

    } else {
      // console.log("APPROVED ALR", this.state.userWantsAllowance[wantAddress.toLowerCase()])
      return contractDeposit(pid)
    }
  }, [userData.data, walletData.autoContract, walletData.poolsByPid, address, notify])

  const withdraw = useCallback(({ pid, amt }) => {
    if (!amt) {
      return
    }
    let contract = walletData.autoContract
    const normalizedAmt = (amt.match(/,/g) || []).length === 1
      ? amt.replace(',', '.')
      : amt

    const parsedAmt = ethers.utils.parseUnits(normalizedAmt, 18)

    return new Promise((resolve, reject) => {
      contract.p.methods.withdraw(pid, parsedAmt ).send({ from: address}, (err, data) => { if (err) { console.log(err) } } )
        .on('error', function(error){
          console.error(error)
          reject(error)
        })
        .on('transactionHash', (transactionHash) => {
          notify("Withdraw pending...");
          console.log(transactionHash, "pending...")
        })
        .on('receipt', (receipt) => {
          console.log(receipt, "receipt") // contains the new contract address
          if (!hashCompletionHandled[receipt.blockHash]){
            hashCompletionHandled[receipt.blockHash] = true
            notify('Withdraw complete')
            userData.refetch()
            resolve(receipt)
          } 
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          // console.log(confirmationNumber, "confirmation") // contains the new contract address
        })
    })
  }, [walletData.autoContract, notify, address, userData.refetch]);

  const harvestAll = useCallback(async () => {
    let contract = walletData.autoContract
    const batch = new web3.BatchRequest()

    const pids = Object.keys(farms.data.pools)
    const pidsWithAutoRewards = pids.filter(pid =>
      userData.data.pendingAUTO[pid] > 1e-6
    )
    const requests = pidsWithAutoRewards.map(pid =>
      new Promise((resolve, reject) => batch.add(
        contract.p.methods.withdraw(pid, 0)
          .send.request({ from: address }, (err, data) => {
            if (err) { return reject(err) }
            resolve(data)
          })
      ))
    )
    batch.execute()
    await Promise.all(requests)
    await new Promise(resolve => setTimeout(resolve, 7000))
    userData.refetch()
    notify('All rewards harvested')
  }, [walletData.autoContract, notify, address,
    userData.data?.pendingAUTO,
    userData.refetch, farms.data, web3
  ]);

  const [selectedFarm, setSelectedFarm] = useState(null)
  useEffect(() => {
    setSelectedFarm(null)
  }, [chain, setSelectedFarm])

  const farmChoices = useMemo(() => compose(
    xs => [{farm: null, farmName: 'All'}, ...xs],
    uniqBy(({ farmName }) => farmName),
    xs => xs.map(
      ({ farm, farmName }) => ({ farm, farmName })
    ),
    xs => xs.map(([pid]) => farms?.data.pools?.[pid]),
    xs => degen
      ? xs.slice(farms?.data?.degenRowOnwards)
      : xs.slice(0, farms?.data?.degenRowOnwards)
  )(farms?.data?.table_data || []), [farms?.data, degen])

  const [sortField, setSortField] = useState(null)

  const [hideEmpty, setHideEmpty] = useState(false)

  const pools = useMemo(
    () => compose(
      hideEmpty
        ? xs => xs.filter(([pid]) =>
          userData.data?.staked?.[pid] > 1e-6
        )
        : identity,
      sortField
        ? sortBy(([pid, multiplier, asset, farm, tvl, apy]) => {
          const pool = farms?.data?.pools?.[pid]
          if (sortField === 'apy') {
            return parseFloat(apy)
          }
          if (sortField === '-apy') {
            return -parseFloat(apy)
          }
          if (sortField === 'tvl') {
            return parseFloat(tvl)
          }
          if (sortField === '-tvl') {
            return -parseFloat(tvl)
          }
          return 0
        })
        : identity,
        xs => selectedFarm ? xs : xs.filter(([pid]) => {
          const { farmName } = farms?.data?.pools?.[pid]
          return farmName.indexOf('Venus') == -1
        }),
      xs => selectedFarm
        ? xs.filter(([pid]) => {
          const { farmName } = farms?.data?.pools?.[pid]
          return farmName === selectedFarm
        })
        : xs,
      xs => degen
        ? xs.slice(farms?.data?.degenRowOnwards)
        : xs.slice(0, farms?.data?.degenRowOnwards)
    )(farms?.data?.table_data || []),
    [degen, farms?.data, selectedFarm, sortField, userData.data, hideEmpty]
  )

  const numHarvestable = useMemo(() => userData.data?.pendingAUTO &&
    Object.values(userData.data?.pendingAUTO)
      .filter(x => x >= 1e-6)
      .length,
    [userData.data]
  )



  const newMultiplierAt = useMemo(() => {
    const pools = bscFarms.data?.pools
    if (!pools) { return false }
    const randomPid = Object.keys(pools).find(pid => {
      const pool = pools[pid]
      return !!pool.newMultiplierAt
    })
    if (!randomPid) {
      return
    }
    const randomPool = pools[randomPid]
    return randomPool.newMultiplierAt
  }, [bscFarms.data])

  const openedTime = useMemo(() => Date.now(), [])
  const showCountdown = useMemo(() =>
    openedTime < newMultiplierAt,
    [openedTime, newMultiplierAt]
  )

  const renderer = useCallback(({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <div>New multipliers in effect</div>
    } else {
      // Render a countdown
      const text = [
        days && `${days}d`,
        `${hours}h`,
        `${minutes}m`,
        `${seconds}s`
      ].filter(Boolean).join(' ')
      return (
        <div className="leading-none">
          New RAKE multipliers in
          <span className="bg-blue-100 dark:bg-blue-900 p-1 rounded text-sm ml-2 whitespace-nowrap font-semibold">
            {text}
          </span>
        </div>
      );
    }
  }, [])

  return (
    <div className="max-w-3xl m-auto flex flex-col space-y-6 bgImageLeft">
      <Dashboard
        stats={stats}
        platformTVL={totalTVL}
        tvls={tvls}
        priceAUTO={stats?.data?.priceAUTO}
        totalPendingAUTO={totalPendingAUTO}
        totalStaked={totalStaked}
        chain={chain}
        showBuyAuto={chain === 'bsc'}
        harvestAll={harvestAll}
        numHarvestable={numHarvestable}
      />

      { farms.isLoading && (
        <div className="text-center py-5 text-xl font-semibold text-gray-500">
          <Loading />
        </div>
      )}

      <Collapse in={farms.isSuccess} timeout={2000}>
        { showCountdown && (
          <div className="text-center bg-gray-100 dark:bg-gray-900 p-3 my-4 rounded-lg">
            <Countdown date={newMultiplierAt} renderer={renderer} />
          </div>
        ) }
        <Chain
          chain={chain}
          setChain={setChain}
          chainId={chainId}
        />
        <ToolBar
          degen={degen}
          hasDegen={hasDegen}
          toggleDegen={toggleDegen}
          selectedFarm={selectedFarm}
          setSelectedFarm={setSelectedFarm}
          farmChoices={farmChoices}
          hideEmpty={hideEmpty}
          setHideEmpty={setHideEmpty}
        />
        <div className="max-w-3xl m-auto">
          <div className="flex flex-col relative">
            <PoolHeader
              sortField={sortField}
              setSortField={setSortField}
              chain={chain}
            />
            { pools?.map((summary, idx) => {
              if (!summary) { return }
              const pid = summary[0]
              const pools = farms.data.pools
              const pool = pools[pid]

              return (
                <Pool
                  key={pid}
                  pid={pid}
                  summary={summary}
                  pool={pool}
                  userPendingAUTO={userData?.data?.pendingAUTO?.[pid]}
                  userWantsBalance={userData?.data?.balances?.[pid]}
                  userStakedWantToken={userData?.data.staked?.[pid]}
                  priceAUTO={stats?.data?.priceAUTO}
                  withdraw={withdraw}
                  deposit={deposit}
                  hasAutoRewards={chain === 'bsc' && !degen}
                  chain={chain}
                />
              )
            })}
            </div>

            <div className="text-sm text-center text-gray-500 my-3">
              Auto-compound assets. Earn RAKE.
            </div>
          <div className="text-sm text-center text-gray-500 my-3">
            <a href="" onClick={(e) => {e.preventDefault();setSelectedFarm('Venus')}}>View Venus Pools</a>
            </div>
          </div>
        </Collapse>

      <div className="pt-64"></div>

    </div>
  )
}

export default Vaults2

const AutoFarmAddress = "0x7f7Bf15B9c68D23339C31652C8e860492991760d"//main
const RakeTokenAddress = "0xbDa8D53fe0F164915b46cd2EcfFD94254b6086a2"//main
const apiDomain = 'https://farm.br34p.finance/' //main

// const AutoFarmAddress = 0x83e53B1c344f30703F5bcA259D561e46b3C06963 //test autofarm
// const RakeTokenAddress = 0xa1838Ce6E38Fd1142c908335fC1cb6503EFA0A61 //Test Rake
// const apiDomain = 'http://localhost:3000/'

module.exports = {
    AutoFarmAddress: AutoFarmAddress,
    RakeTokenAddress: RakeTokenAddress,
    apiDomain: apiDomain,
}

import * as React from 'react'
import BotNavBar from './BotNavBar'

import Vaults2 from './Vaults2'
import { ToastContainer, toast, Slide } from 'react-toastify';
import constants from './constants'

import 'react-toastify/dist/ReactToastify.css';
// import background from "../../images/rake_text.PNG";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      page: "v2_vaults",
    };
  }

  notify = (msg) => toast.dark(msg);

  render = () => {
    const props = {
      v2: true,
      notify: this.notify,
      connectionOK: this.props.connectionOK,
      connected: this.props.connected,
      chainId: this.props.chainId,
      address: this.props.address,
      web3: this.props.web3,
      web3_np: this.props.web3_np,
      chain: this.props.chain,
      setChain: this.props.setChain,
    }

    return (
      <div>
        <div style={{ minHeight: "630px",
          // backgroundImage: `url(${background})`,
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "left center"

        }}>
          <ToastContainer
            transition={Slide}
            position="bottom-left"
            hideProgressBar
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Switch>
            <Route path="/"><Vaults2 {...props} /></Route>
          </Switch>
          {constants.mode == "prod" ? "" : <h3>Mode: {constants.mode}</h3>}
        </div>
        <BotNavBar/>
      </div>
    )
  };
}

export default App


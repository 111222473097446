import { memo, useMemo, useCallback } from 'react'
import commaNumber from 'comma-number'
import { Grid, Collapse } from '@material-ui/core'
import { useMutation } from 'react-query'

import constants from './constants'
import { formatNumber, currency } from './lib/numFormat'

const Dashboard = ({
  platformTVL,
  tvls = {},
  priceAUTO,
  totalPendingAUTO,
  totalStaked,
  harvestAll,
  chain,
  numHarvestable = 0,
  showBuyAuto = true
}) => {
  const harvestAllMutation = useMutation(harvestAll)
  const handleHarvestAll = useCallback(() => {
    harvestAllMutation.mutate()
  }, [harvestAllMutation])

  return (
    <div className="max-w-3xl w-full m-auto my-6 text-left px-4">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={7}>
          <div className="flex-auto rounded-lg">
            <div className="font-semibold text-3xl">
              TVL {currency(platformTVL, 0)}
            </div>
            <div className="font-semibold">
              {/*{chain?.toUpperCase()} TVL {currency(tvls?.[chain], 0)}*/}
            </div>

            <Collapse in={ showBuyAuto }>
              <div className="font-semibold">
                RAKE {currency(priceAUTO)}
              </div>
              <button
                onClick={() => window.open(`https://exchange.pancakeswap.finance/#/swap?outputCurrency=${constants.AUTOv2Address}`) }
                className="btn btn-secondary py-1 mt-1"
              >
                Buy RAKE
              </button>
              <button
                  onClick={() => window.open(`https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xa86d305a36cdb815af991834b46ad3d7fbb38523`) }
                  className="btn btn-secondary py-1 mt-1 text-white"
              >
                Buy BR34P
              </button>
              <button
                  onClick={() => window.open('https://twitter.com/r34pTOKEN')}
                  className="btn btn-secondary py-1 mt-1 text-yellow-600 dark:text-yellow-500"
              >
                Twitter
              </button>
              <button
                  onClick={() => window.open('https://silo.br34p.finance') }
                  className="btn btn-secondary py-1 mt-1 text-green-600 dark:text-green-500"
              >
                SILO
              </button>
            </Collapse>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <div className="">
            <div className="flex justify-between">
              <div className="font-semibold">Total Deposit</div>

              <div className="text-right">
                <div>{currency(totalStaked)}</div>
              </div>
            </div>
            <Collapse in={ showBuyAuto }>
              <div className="flex justify-between">
                <div className="font-semibold">RAKE Rewards</div>

                <div className="text-right">
                  <div className={totalPendingAUTO > 0 ? 'text-green-600 dark:text-green-500' : ''}>
                    {formatNumber(totalPendingAUTO)}
                  </div>
                  <div className="text-sm">{currency(totalPendingAUTO * priceAUTO)}</div>
                  <Collapse in={ totalPendingAUTO > 1e-6 }>
                    <button
                      className="btn-secondary mt-1"
                      onClick={handleHarvestAll}
                      disabled={harvestAllMutation.isLoading}
                    >
                      { harvestAllMutation.isLoading
                        ? 'Harvesting...'
                        : `Harvest all (${numHarvestable} vaults)`
                      }
                    </button>
                  </Collapse>
                </div>
              </div>
            </Collapse>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default memo(Dashboard)


import { memo, useCallback } from 'react'
import { FormControlLabel, Switch, Collapse, Checkbox } from '@material-ui/core'
import classnames from 'classnames'

const degenWarning = (
  <div className="dark:text-yellow-400 text-yellow-600 text-sm leading-tight mt-2">
    <div style={{fontWeight:"bold", fontSize:"16px"}}> WARNING </div>
    <div> These farms have NOT been reviewed by the autofarm&nbsp;team. </div>
    <div> <b>DYOR</b>, use at your own risk.  </div>
  </div>
)

const ToolBar = ({
  degen,
  hasDegen,
  toggleDegen,
  farmChoices = [],
  setSelectedFarm,
  selectedFarm,
  hideEmpty,
  setHideEmpty
}) => {
  const handleChangeEmpty = useCallback(() => {
    setHideEmpty(e => !e)
  }, [setHideEmpty])

  return (
    <div className="py-1 px-3 space-y-2">
      <div className="flex">
        <Collapse in={hasDegen}>
          <div className="flex items-center">
            {/*<Switch*/}
            {/*  onClick={toggleDegen}*/}
            {/*  checked={degen}*/}
            {/*  value="degen"*/}
            {/*  color="default"*/}
            {/*  disableRipple*/}
            {/*  edge="start"*/}
            {/*  inputProps={{ 'aria-label': 'secondary checkbox' }}*/}
            {/*/>*/}
            {/*<div className={classnames(*/}
            {/*  'font-semibold text-sm sm:text-base',*/}
            {/*  degen && 'dark:text-yellow-400 text-yellow-600'*/}
            {/*)}>*/}
            {/*  TURBO*/}
            {/*</div>*/}
          </div>
        </Collapse>
      </div>
      <div>
        <div className="font-semibold text-base text-gray-500 dark:text-gray-400 leading-none mb-2">Farm</div>
        <div className="flex space-x-2 text-sm items-center overflow-x-auto">
          {farmChoices.map(({ farm, farmName }) => (
              farmName != "Venus" &&
            <div
              key={farm}
              onClick={() => setSelectedFarm(farmName === 'All' ? null : farmName)}
              className={classnames(
                selectedFarm === farmName || selectedFarm == null && farmName === 'All'
                  ? 'dark:bg-white dark:text-black bg-gray-700 text-white'
                  : 'dark:bg-gray-900 dark:hover:bg-gray-800 bg-gray-100 hover:bg-gray-200',
                "cursor-pointer rounded-full px-3 py-1 transition"
              )}
            >
              {farmName}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end pt-3">
        <div
          className="flex items-center leading-none space-x-2
          rounded dark:bg-gray-900 p-2 select-none cursor-pointer
          dark:hover:bg-gray-800 text-sm"
         >
          <div>
            <label className="cursor-pointer" htmlFor="hideEmpty">Staked only</label>
          </div>
          <input
            type="checkbox"
            className="cursor-pointer form-checkbox h-5 w-5 text-gray-600"
            checked={hideEmpty}
            name="hideEmpty"
            id="hideEmpty"
            onChange={handleChangeEmpty}
          />
        </div>
      </div>
      <div className="flex-auto">
        <Collapse in={!!degen}>
          {degenWarning}
        </Collapse>
      </div>
    </div>
  )
}

export default memo(ToolBar);

import React, { memo } from 'react';
import constants from './constants'
import { Grid } from '@material-ui/core'


const BotNavBar = ({ chain = 'bsc' }) => {
  const links = [
    [
      ["https://github.com/br34p/", 'Github'],
      [constants.blockExplorerURLBase[chain] + "address/" + constants.autoFarmV2ContractAddress.bsc, 'Contract'],
      ["https://rakefarm.helpsite.com/", "Wiki"],
      ["https://t.me/Br34P_Chat",  'Telegram'],
    ]
  ]
  return (
    <div className="bg-gray-900 text-white absolute bottom-0 left-0 right-0">
      <div className="max-w-3xl m-auto px-3 pt-5 pb-7">
        { links.map((line, i) => (
          <Grid container key={i} spacing={2}>
            {line.map(([url, label]) => (
              <Grid item xs={3} className="text-center" key={url}>
                <a href={url} target="_blank">{label}</a>
              </Grid>
            ))}
          </Grid>
        )) }
      </div>
    </div>
  )
}

export default memo(BotNavBar)


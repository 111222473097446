import { memo, useContext } from 'react'
import classnames from 'classnames'
import { IconButton } from '@material-ui/core'
import { Close, NightsStay, WbSunny } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import ThemeContext from '../context/theme'
import logo from '../../images/rake_notext.PNG'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const Header = ({ onConnect, connected, connectionOK, address, resetApp, loadingWallet }) => {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const darkMode = theme === 'dark'

  return (
    <div className="p-2">
      <div className="max-w-4xl m-auto flex items-center justify-between space-x-2">
        <div className="flex items-center space-x-1 sm:space-x-2">
          <div>
            <img className="w-8" src={logo} alt="" />
          </div>
          <div className="flex flex-col">
            <span className="font-bold leading-none text-xl"> RAKE-FARM</span>
            <span className="leading-none text-sm whitespace-nowrap">Raking in Rewards</span>
          </div>
        </div>

        <div className="space-x-6 items-center hidden sm:flex">
          <Link to="/" className="hover:underline">Vaults</Link>
        </div>
        <div className="flex items-center space-x-2 lg:space-x-4">
          <div onClick={toggleTheme} className="cursor-pointer">
            { darkMode ? <NightsStay /> : <WbSunny /> }
          </div>
          <div
            onClick={connected ? resetApp : onConnect}
            className={classnames(
              !connected || connectionOK ? "btn-tertiary" : 'btn-error',
              'space-x-2'
            )}
          >
              <>
                { connectionOK && <Close /> }
                <div>
                  { connectionOK && address
                    && (address.slice(0, 5) + "..." + address.slice(-4))
                  }
                  { connectionOK === false && 'Wrong Network' }
                  { connectionOK === null && 'Connect Wallet' }
                </div>
              </>
          </div>
        </div>
      </div>
        <div className="space-x-2 items-center flex sm:hidden pt-5 justify-center">
          <Link to="/" className="py-1 px-3">Vaults</Link>
        </div>
    </div >
  )
}

export default memo(Header)


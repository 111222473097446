import React from 'react'

let SHOWMODEL = false

export function isModalVisible() {
    return SHOWMODEL
}

export function setModalVisible(isVisible) {
    SHOWMODEL = !!isVisible;
}

const ModalContext = React.createContext({
    showModal: false,
    toggleModal: () => {}
})

export default ModalContext

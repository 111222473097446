import { useState } from 'react';

const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function hasAccepted(pid){
        let str = pid + "Accepted"
        let accepted1 = localStorage[str] || false
        if (accepted1 === "true"){
            accepted1 = true
        }

        if (accepted1 === "false"){
            accepted1 = false
        }

        return accepted1
    }

    function toggleAccept1(accepted, pid){
        let str = pid + "Accepted"
        localStorage[str] = Boolean(accepted)
    }

    let ClearRiskAgreement = function (){
        for (let i=0; i< 100;i++){
            localStorage.removeItem(i + "Accepted");
        }
    }

    function toggle() {
        ClearRiskAgreement()
        setIsShowing(!isShowing);
    }

    return {
        isShowing,
        toggle,
        toggleAccept1,
        hasAccepted,
    }
};

export default useModal;